import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';


function TwoFactor( {authenticatedUser} ) {  
    const navigate = useNavigate();
    const [qrCode, setQRCode] = useState('');
    const [twoFAEnabled, setTwoFAEnabled] = useState(false);

    if(authenticatedUser == null){
        navigate("/login");
    }

    useEffect(() => {
        axios.get('https://tcotinc.com:3005/checkTwoFAEnabled', {withCredentials:true})
        .then(response => {
            //console.log(response.data)
            console.log("TwoFAEnabled Code: " + response.data.TwoFAEnabled)
            setTwoFAEnabled(response.data.TwoFAEnabled);
        })
        .catch(error => console.error('Error fetching two factor information:', error));
    });

    const enableTwoFA = () => {
        //console.log("Attempting request to server to enable 2fa")
        axios.get('https://tcotinc.com:3005/enableTwoFA', {withCredentials:true})
        .then(response => {
            //console.log(response.data)
            setQRCode(response.data.qrCodeURL);
        })
        .catch(error => console.error('Error fetching two factor information:', error));
        console.log("outside of axios request")
    }


    return (
        <div className="twoFactor-app">
        
        <div className="twoFactor-header">
            <h1>Two Factor Authentication Info for {authenticatedUser}</h1>
            {!twoFAEnabled && <button onClick={enableTwoFA}>Enable 2FA</button>}
            {twoFAEnabled && <p>Two Factor Authentication is enabled for {authenticatedUser}. Contact site administrator for help resetting Two Factor Authentication.</p>}
            {qrCode && <img src={qrCode} alt="QR Code" />}
        </div>

        </div>
    );
}

export default TwoFactor;