import React from "react";
 
const Home = () => {
    return (
        <div class="home-app">
            <h1>Welcome to TCOT Marketing Tool</h1>
            <p>Disclaimer: All information contained herein is proprietary to T-COT and is provided solely for the purpose of obtaining a contract with T-COT. Unauthorized use, disclosure, or distribution of this information is strictly prohibited. Any other use of this information without the express written consent of T-COT is not permitted.</p>
        </div>
    );
};
 
export default Home;