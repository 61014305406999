import React from 'react';
import { NavLink } from "./NavbarElements";

function AuthenticateNav({authenticatedUser}){

    return (
        <>
            { authenticatedUser.authenticatedUser === null ? 
                <>
                    <NavLink to="/login" activestyle="true">
                        Login
                    </NavLink>
                </>
            :
                <>
                    <NavLink to="/search" activestyle="true">
                        Search
                    </NavLink>
                    <NavLink to="/taxpayer" activestyle="true">
                        Taxpayer
                    </NavLink>
                    <NavLink to="/twoFactor" activestyle="true">
                        Two Factor Authentication
                    </NavLink>
                    <NavLink to="/logout" activestyle="true">
                        Logout
                    </NavLink>
                </>
            }
        </>
    );
}
 
export default AuthenticateNav;