import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import './taxpayer.css';


function Taxpayer( {authenticatedUser} ) {  
  const navigate = useNavigate();
  const location = useLocation();

  const [tpName, setTPName] = useState('');
  const [lowpro, setLowPro] = useState('');
  const [mktcosts, setMktCosts] = useState('');
  const [address, setAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [officers, setOfficers] = useState([]);
  const [showOfficers, setShowOfficers] = useState(false);
  const [lastUpdatedDate, setLastUpdatedDate] = useState('');
  const [drillingPermits, setDrillingPermits] = useState('');

  const [tempTP, setTempTP] = useState('');

  if(authenticatedUser == null){
    navigate("/login");
  }

  useEffect(() => {
    
    if(!location.state)
      setTempTP('32054404077')
    else {
      setTempTP(location.state.data.TPNumber)
    }

    axios.get('https://tcotinc.com:3005/LowPro/' + tempTP, {withCredentials:true})
    .then(response => {
      setTPName(response.data[0][0].TaxpayerName)
      setLowPro(response.data[1][0].LowPro);
      setMktCosts(response.data[2][0].MktCosts);
      if(response.data[3][0] !== undefined)
        setAddress(response.data[3][0].Address);
      else
        setAddress("N/A");
      if(response.data[4][0] !== undefined)
        setPhoneNumber(response.data[4][0].Phone);
      else
        setPhoneNumber("N/A");
      if(response.data[5] !== undefined) {
        setOfficers(response.data[5])
      }
      else console.log("No officer data :(");
      setLastUpdatedDate(response.data[6][0].UpdatedDate);
      setDrillingPermits(response.data[7][0].NumOfPermits);
    })
    .catch(error => console.error('Error fetching lowpros:', error));
  }, [location, tempTP]);

  const changeOfficers = () => {
    if(!showOfficers)
      setShowOfficers(true);
    else
      setShowOfficers(false);
  }


  return (
    <div className="taxpayer-app">
      
      <div className="taxpayer-header">
        <h1>Taxpayer Summary</h1>
        <p>Disclaimer: All information contained herein is proprietary to T-COT and is provided solely for the purpose of obtaining a contract with T-COT. Unauthorized use, disclosure, or distribution of this information is strictly prohibited. Any other use of this information without the express written consent of T-COT is not permitted.</p>
      </div>
      
      <div className="taxpayer-all">

        <div className="taxpayer-info">

          <ul className="info-list">
            <li key={"taxpayerName"}>
              {"Client Status if current or past"}
            </li>
            <li key={"taxpayerName"}>
              {tpName + " Summary as of " + lastUpdatedDate}
            </li>
            <li key={"taxpayerNumber"}>
              {"Taxpayer Number: " + tempTP}
            </li>
            <li key={"address"}>
              {"Taxpayer Address: " + address}
            </li>
            <li key={"phoneNumber"}>
              {"Phone Number: " + phoneNumber}
            </li>
          </ul>
          {showOfficers && <button onClick={changeOfficers}>Hide Officers</button>}
          {!showOfficers && <button onClick={changeOfficers}>Show Officers</button>}
          {showOfficers && officers.map((officerObj, i) => (
            <ul className='officer-list'>
              <li>{officerObj.Title}: {officerObj.Name}</li>
            </ul>
          ))}
        </div>
        <div className='middle-div'></div>
        <div className="refund-info">
          <ul className="refund-list">
            <li key={"newDP"}>
              {"New Drill Permits: " + drillingPermits}
            </li>
            <li key={"oilWells"}>
              {"Operated Oil Wells: "}
            </li>
            <li key={"oil2024"}>
              {"2024: " + null}
            </li>
            <li key={"oil2023"}>
              {"2023: " + null}
            </li>
            <li key={"oil2022"}>
              {"2022: " + null}
            </li>
            <li key={"oil2021"}>
              {"2021: " + null}
            </li>
            <li key={"gasWells"}>
              {"Operated Gas Wells: " + null}
            </li>
            <li key={"lowpro"}>
              {"Low Production: " + lowpro}
            </li>
            <li key={"marketingCosts"}>
              {"Marketing Costs: " + mktcosts}
            </li>
            <li key={"highCost"}>
              {"High Cost: " + null}
            </li>
            <li key={"gor"}>
              {"GOR: " + null}
            </li>
            <li key={"twoYearInactive"}>
              {"Two Year Inactive: " + null}
            </li>
            <li key={"flare"}>
              {"Flare: " + null}
            </li>
          </ul>
        </div>
      </div>

    </div>
  );
}

export default Taxpayer;