import { useNavigate } from "react-router-dom";
import axios from 'axios';

const Logout = ( {authenticatedUser, setAuthenticatedUser} ) => {
    const navigate = useNavigate();

    const logoutRequest = () => {
        if(authenticatedUser != null) {
            axios.delete('https://tcotinc.com:3005/logout/', {withCredentials:true} )
            .then(response => {
                setAuthenticatedUser(null);
                console.log(response.data.message);
                navigate("/login");
            })
            .catch(error => {
                console.log(error)
            });
        }
        else {
            navigate("/login")
        }
    }
            

    return (
        logoutRequest()
    )
};
 
export default Logout;